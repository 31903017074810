import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import Header from '../components/Header'

import { H3, Link, Container, PostMeta } from '../styled'

const PostItem = styled.div`
  margin: 6rem 0rem;
`

const BlogIndex = props => {
  console.log(props)
  const { data } = props
  console.log(data)
  const posts = data.allMdx.edges

  return (
    <Layout location={props.location} title={'welcome.'}>
      <SEO
        title="home"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        key={'SEO'}
      />
      {posts
        .filter(x => {
          return process.env.NODE_ENV === `production`
            ? x.node.frontmatter.public
            : true
        })
        .map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <>
              <PostItem key={node.fields.slug}>
                {/* <div className="left">
                </div>
                <div className="right excerpt">
                </div> */}
                <h2 className="title">
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h2>
                <div className="excerpt">
                  <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </div>
                <PostMeta>
                  {node.frontmatter.date} &middot; {node.timeToRead} min read
                </PostMeta>
              </PostItem>
            </>
          )
        })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            public
          }
        }
      }
    }
  }
`
